
import SEO from '~/mixins/SEO.js'

export default {
  name: 'IndexPage',
  mixins: [SEO],
  async asyncData({ app }) {
    let posts = []
    try {
      posts = await app.$wp.posts().perPage(1).page(1)
      for (let index = 0; index < posts.length; index++) {
        const post = posts[index]
        const images = post.content.rendered.match(
          /<img[^>]* src="([^"]*)"[^>]*>/gi
        )
        const links = post.content.rendered.match(/<a\b[^>]*>([\s\S]*?)<\/a>/gmi)
        const newLinks = []
        if (links) {
          for (let index = 0; index < links.length; index++) {
            const element = links[index]
            const anchorArray = element.match(/<a\b[^>]*>([\s\S]*?)<\/a>/i)
            const anchor = anchorArray[1].trim()
            newLinks.push(
              (!element.includes('class=')
                ? element.replace(
                    'href=',
                    'class="bbit-link" href='
                  )
                : element.replace('class="', 'class="bbit-link')
              ).replace(
                'href=',
                !element.includes('//bielbit.pl/') &&
                  !element.includes('//www.bielbit.pl/')
                  ? 'title="Przejdź do strony ' + anchor + '" rel="nofollow" href='
                  : 'title="Przejdź do strony ' + anchor + '" href='
              )
            )
          }
          for (let index = 0; index < links.length; index++)
            post.content.rendered = post.content.rendered.replace(
              links[index],
              newLinks[index]
            )
        }
        for (let index = 0; index < images.length; index++)
          post.content.rendered = post.content.rendered.replace(
            images[index],
            ''
          )
        for (let index = 0; index < images.length; index++)
          images[index] = images[index]
            .match(/src=".*\..{3,4}"/gi)[0]
            .replace(/src=/gi, '')
            .replace(/"/g, '')
            .replace(
              'https://projektblog.bielbit.pl/wp-content/',
              '/projekt-wp-content/'
            )
        posts[index].images = images
        // console.log(posts[index])
      }
    } catch (error) {
      console.error(error)
    }
    return {
      newsModal: {
        id: 'newsModal',
        posts,
      },
    }
  },
  data() {
    return {
      modal: {
        id: 'popupModal',
      },
      heroUnitImg: '/grafika_tytulowa@2x.png',
      rawPageTitle: 'IT po partnersku',
      rawPageDescription:
        'Stawiamy na długotrwałą współpracę opartą na rozwiązaniach indywidualnie dobranych do Twoich potrzeb. Szukasz oprogramowania dla firmy którą prowadzisz? Wspólnie znajdziemy drogę do rozwoju Twojego przedsiębiorstwa. ',
      keywords: [
        'firma',
        'partner',
        'część',
        'oprogramowanie',
        'bielbit',
        'rozwiązanie',
        'potrzeba',
        'komputerowy',
        'problem',
        'sprzedaż',
        'księgowość',
        'system',
        'bielski',
        'insert',
        'serwis',
        'biuro',
        'rachunkowy',
      ],
    }
  },
  jsonld() {
    return {
      '@context': 'http://schema.org/',
      '@type': [
        'LocalBusiness',
        'AccountingService',
        'ProfessionalService',
        'ComputerStore',
      ],
      name: 'BIELbit sp. z o.o.',
      legalName: 'BIELbit sp. z o.o.',
      slogan: 'IT po partnersku',
      logo: 'https://www.bielbit.pl/icon.png',
      image: 'https://www.bielbit.pl/icon.png',
      telephone: '+48 33 472 33 33 ',
      url: 'https://www.bielbit.pl/',
      email: 'bielbit@bielbit.pl',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'ul. Komorowicka 45',
        addressLocality: 'Bielsko-Biała',
        addressRegion: 'śląskie',
        postalCode: '43-300',
        addressCountry: 'Poland',
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: 49.8324264,
        longitude: 19.0512995,
      },
      hasMap:
        'https://www.google.com/maps/place/Komorowicka+45,+43-300+Bielsko-Bia%C5%82a/@49.8324264,19.0512995,17z/data=!3m1!4b1!4m5!3m4!1s0x4716a1e21db10573:0xc0f314c8b6fc3202!8m2!3d49.8324264!4d19.0534882',
      openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          opens: '08:00',
          closes: '16:00',
        },
      ],
      sameAs: ['https://pl.linkedin.com/company/bielbit'],
    }
  },
  computed: {
    preparedDataForNewsModal() {
      let modalId = this.newsModal.id
      for (let index = 0; index < this.newsModal.posts.length; index++) {
          const post = this.newsModal.posts[index]
          post.title.rendered = this.stripHtmlTagsFromText(post.title.rendered)
          modalId = this.newsModal.id + '-' + post.id + '-' + post.slug
      }
      console.log(modalId)
      return {
        id: modalId,
        posts: this.newsModal.posts
      }
    }
  },
  mounted() {
    console.log(new Date())
    if (!this.$cookies.get('is-'+this.preparedDataForNewsModal.id))
      setTimeout(() => {
        console.log(new Date())
        // Kod, który ma zostać wykonany po 10 sekundach
        console.log('Otwieram modal z newsem po 5 sekundach');
        this.$bvModal.show(this.preparedDataForNewsModal.id)
      }, 5000); // 5000 milisekund = 5 sekund
    else
      console.log(this.preparedDataForNewsModal.id + ' is hidden by cookie')
    //  if (!this.$cookies.get('isPopupShowedTemp3'))
    //    this.$bvModal.show(this.modal.id)
  },
}
