import { render, staticRenderFns } from "./index.vue?vue&type=template&id=bb1fbe3a"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=bb1fbe3a&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroUnit: require('/var/www/html/bielbit/www2022/upd/components/HeroUnit.vue').default,ButtonTo: require('/var/www/html/bielbit/www2022/upd/components/ButtonTo.vue').default,SectionContent: require('/var/www/html/bielbit/www2022/upd/components/SectionContent.vue').default,PartnersCarousel: require('/var/www/html/bielbit/www2022/upd/components/PartnersCarousel.vue').default,SiteFooter: require('/var/www/html/bielbit/www2022/upd/components/SiteFooter.vue').default,StickyFooter: require('/var/www/html/bielbit/www2022/upd/components/StickyFooter.vue').default,PopupModal: require('/var/www/html/bielbit/www2022/upd/components/PopupModal.vue').default,NewsPopup: require('/var/www/html/bielbit/www2022/upd/components/NewsPopup.vue').default})
