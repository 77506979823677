import { render, staticRenderFns } from "./NewsPopup.vue?vue&type=template&id=63df85ad"
import script from "./NewsPopup.vue?vue&type=script&lang=js"
export * from "./NewsPopup.vue?vue&type=script&lang=js"
import style0 from "./NewsPopup.vue?vue&type=style&index=0&id=63df85ad&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NoConnectionToNews: require('/var/www/html/bielbit/www2022/upd/components/NoConnectionToNews.vue').default,ButtonTo: require('/var/www/html/bielbit/www2022/upd/components/ButtonTo.vue').default})
